import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { Routes } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './+state/app-router/reducers';
import { RouterEffects } from './+state/app-router/app-router.effects';
@NgModule({
  imports: [CommonModule, StoreModule.forFeature('router', reducers), EffectsModule.forFeature([RouterEffects])],
})
export class AppRouterModule {}
