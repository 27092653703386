import { INewCost } from 'libs/api/src/interfaces';

export interface INewStock {
  id?: any;
  type: string;
  shape?: string;
  size?: {
    [key: string]: number;
  };
  length?: number;
  partLength?: number;
  width?: number;
  pricedPartStock?: any;
  useStockPrice?: boolean;
  lengthHasPricing?: boolean;
  shapeHasPricing?: boolean;
  sizeHasPricing?: boolean;
  isUserDefined?: boolean;
  costs?: INewCost[];
  totalCost?: number;
  totalAdditionalCost?: number;
  linkedCost?: string;
}

// OLD
export interface IStock {
  type: string;
  shape: string;
  size: {
    [key: string]: number;
  };
  length?: number;
  pricedPartStock?: any;
}

export interface IUserStock extends IStock {
  user: string;
}

export interface IBusinessStock extends IStock {
  id: string;
}

// FRONTEND
export interface Stock {
  type: string;
  size?: {
    [key: string]: number;
  };
  shape?: string;
  length?: number;
}

export interface BusinessStock extends Stock {
  id: string;
}
