// Forked from https://github.com/mikolalysenko/minimal-bit-array
export class BitArray {
  public length;
  public bits;

  constructor(length) {
    this.length = length;
    this.bits = new Uint32Array((length >>> 5) + 1);
  }

  get(i) {
    i |= 0;
    return !!(this.bits[i >>> 5] & (1 << (i & 31)));
  }

  set(i, v) {
    i |= 0;
    var idx = i >>> 5;
    var bit = 1 << (i & 31);
    if (v) {
      this.bits[idx] |= bit;
      return true;
    } else {
      this.bits[idx] &= ~bit;
      return false;
    }
  }

  toJSON() {
    return {
      bits: Array.prototype.slice.call(this.bits),
      length: this.length,
    };
  }

  toIndexMap(): number[] {
    const outArray = new Array<number>(this.length);
    for (let i = 0; i < this.length; i++) {
      outArray[i] = this.get(i) ? i : -1;
    }

    return outArray.filter(v => v !== -1); //Array.prototype.slice.call(this.bits).map((b, i) => i);
  }
}

export function fromJSON(obj) {
  var r = new BitArray(obj.length);
  r.bits.set(obj.bits);
  return r;
}
