import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ActiveDomainInterceptorService, LocalStorageService, TokenInterceptorService } from '@zc/ui-auth/handlers';

import { HttpApiService } from './api.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    HttpApiService,
    TokenInterceptorService,
    LocalStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ActiveDomainInterceptorService,
      multi: true,
    },
  ],
})
export class ApiServiceModule {}
