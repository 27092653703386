export interface Material {
  name: string;
  description: string;
  kind: string;
  pricePerPound: number;
  density: number;
}

export interface BusinessMaterial extends Material {
  id: string;
  fastcastingsId?: string;
}
export interface IMaterialFinishes {
  name: string;
  active: boolean;
  description: string;
  time_factor: number;
}
export interface IMaterial {
  matIndex: number;
  AISI: string;
  UNS: string;
  ASTM: string;
  SAE: string;
  description: string;
  materialClass: number;
  density: number;
  hpTurn: number;
  hpDrill: number;
  hpMill: number;
}

export interface IMatMaterial extends IMaterial {
  id?: any;
  type: string;
}

export interface IFCMaterial extends IMaterial {
  id?: any;
  fastcastingsId?: string;
}
