import { IWork, WorkType, INewWorkflowLayout, IAlternateQuantitiesDetailed, IResults } from '@zc/api';

export interface IEstimate {
  processName: WorkType; // rename this to processName or something?
  //costs: INewCost[]; // leave this on work
  //operations: INewOperation[]; //leave this on work
  //features: INewFeature[]; // leave this on work
  work: IWork[]; // add this to estimate
  workflows: INewWorkflowLayout[]; // add to estimate. this is machine setup and op ordering that comes updated from workflow UI
  layouts: any[]; // add to estimate. these are the results from MEL that are displayed in workflow UI
  calculationResults: IResults[] | null; // this will be on estimate
  setupTime: number; //this will be a total of work
  loadTime: number; // this will be a total of work
  runtime: number; // this will be a total of work
  cycleTime: number; //this will be a total of work
  cost: number; // this will be a total of work
  additionalCost: number; // this will be a total of work
  totalRunTime: number; //runtime * quantity
  totalCost: number; //cost * quantity
  totalAdditionalCost: number; //additionalCost * quantity;
  calculatorError?: string;
  alternateQuantities?: IAlternateQuantitiesDetailed[];
}
