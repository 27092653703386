import { VariableType } from './item';

export interface IMachine {
  companyId: string;
  machineName: string;
  machineStyle: string;
  moreDetails: VariableType<string | number | boolean | null>;
  defaultCostGroup?: string; // ID of Cost
  availableCostGroups?: string[]; // IDs of Cost
  operations?: any[];
}

export interface IBusinessMachine extends IMachine {
  id: string;
}

export interface Machine {
  _id?: any;
  id?: string;
  machineName: string;
  machineStyle: string;
  moreDetails: VariableType<string | number | boolean | null>;
}

export interface MachineTimes {
  totalCutTime: number;
  totalIdleTime: number;
  setupTime: number;
  loadTime: number;
  grossProduction: number;
  cycleTime: number;
}
