import { Params } from '@angular/router';
import { RouterAction, RouterReducerState, ROUTER_CANCEL, ROUTER_ERROR, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { createFeatureSelector } from '@ngrx/store';
import { RouterActionTypes, RouterActive } from '../app-router.actions';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  path: string;
  data: { [key: string]: any };
}

export interface IRouterState extends RouterReducerState<RouterStateUrl> {
  activeRoute: RouterStateUrl;
  previousRoute: RouterStateUrl;
}

export const initialState: IRouterState = {
  navigationId: undefined,
  state: undefined,
  activeRoute: undefined,
  previousRoute: undefined,
};

export function reducer(
  state: IRouterState = initialState,
  action: RouterAction<any, any> | RouterActive,
): IRouterState {
  switch (action.type) {
    case RouterActionTypes.ROUTER_ACTIVE: {
      return {
        ...state,
        activeRoute: action.payload.routerState,
        previousRoute: state.activeRoute,
      };
    }
    case ROUTER_NAVIGATION:
    case ROUTER_ERROR:
    case ROUTER_CANCEL:
      return {
        ...state,
        state: action.payload.routerState,
        navigationId: action.payload.event.id,
      };
    default: {
      return state;
    }
  }
}

export const getRouterState = createFeatureSelector<IRouterState>('router');
