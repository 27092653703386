export enum E_QuoteMetricTypes {
  QUOTE_CALCULATOR_REQUEST = 'quote calculator request',
  QUOTE_CALCULATOR_REQUEST_SUMMARY = 'summarized quote calculator request',
  QUOTE_CALCULATOR_REQUEST_BILLING = 'billing quote calculator request',
}

export interface IQuoteMetricKey {
  company: string;
  user?: string;
  onBehalfOfUser?: string;
  onBehalfOfCompany?: string;
  quoteId?: string;
}

export interface IQuoteMetric extends IQuoteMetricKey {
  type?: E_QuoteMetricTypes;
}

export interface IBusinessQuoteMetric {
  id: string;
}
