import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { RouterStateUrl } from './router.reducer';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;

    let route = routerState.root;
    const path: any[] = [];

    let { params, queryParams, data } = route;

    while (route.firstChild) {
      route = route.firstChild;
      params = { ...params, ...route.params };
      queryParams = { ...queryParams, ...route.queryParams };
      data = { ...data, ...route.data };
      path.push(route.routeConfig.path);
    }

    const pathString = path.filter((s) => !!s).join('/');
    return { url, params, queryParams, path: pathString, data };
  }
}
