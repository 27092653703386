import cloneDeep from 'lodash/cloneDeep';
import { IAddress } from './address';
import { EmptyBusinessCosts, IBusinessCosts } from './cost';
import { EmptyProfit, IProfit } from './profit';

export interface Company {
  id: string;
  name: string;
  domain: string;
  logo: string;
  logoPrint: string;
  footerPrint: string;
  favicon: string;
  links: ICompanyLinks;
}

export interface ICompanyMachinePrivileges {
  count: number;
  limit: number;
}

export interface ICompany {
  name: string;
  logo: string;
  logoPrint: string;
  footerPrint: string;
  favicon: string;
  domain: string;
  syncCompanyID: number;
  signupAllowed: boolean;
  signupRole: string;
  defaultMarkups: IProfit[];
  laborCodes: IBusinessCosts[];
  links: ICompanyLinks;
  apiKeys: {
    stripePublicKey: string;
  };
  stripeCustomerId: string;
  primaryContact: any;
  primaryContactDetails?: any;
  mesInfo?: ICompanyMesInfo;
  mailingAddress: IAddress;
}

export interface ICompanyBranding {
  logo: string;
  logoPrint: string;
  footerPrint: string;
  favicon: string;
}

export interface ICompanyLinks {
  homePage: { title: string; src: string };
  supportPage?: { title: string; src: string };
  privacyPolicy: { title: string; src: string };
  termsOfService: { title: string; src: string };
  termsAndConditions: { title: string; src: string };
  supportEmail?: { title: string; src: string };
  betaTestingLicense?: { title: string; src: string };
}

export const COMPANY_SIGNUP_LINKS = ['privacyPolicy', 'termsOfService', 'termsAndConditions', 'betaTestingLicense'];

export interface IBusinessCompany extends ICompany {
  id: string;
}

export interface ICompanyPublicInfo {
  id: string;
  name: string;
  logo: string;
  logoPrint: string;
  footerPrint: string;
  favicon: string;
  domain: string;
  signupAllowed: boolean;
  signupRole: string;
  laborCodes: IBusinessCosts[];
  defaultMarkups: IProfit[];
  links: ICompanyLinks;
  mailingAddress: IAddress;
}

// export interface ICompanyRestrictedInfo extends ICompanyPublicInfo {
//   syncCompanyID: number;
//   apiKeys: {
//     stripePublicKey: string;
//   };
//   stripeCustomerId: string;
//   primaryContact: any;
// }

export interface ICompanyMesInfo {
  companyId?: string;
  precisionCompanyId?: number;
  duplicatePrecisionCompanyIds?: number[];
  perpetualLicense?: boolean;
  maintenanceValue?: string;
  maintenanceAmount?: string;
  companyCode?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  phone?: string;
  fax?: string;
  currentVersion?: string;
  licensedVersion?: string;
  //customerDetailID?: string, String,
  purchaseDate?: Date;
  maintExpDate?: Date;
  swExpDate?: Date;
  maintLevel?: string;
  systemType?: string;
  securityKeyId?: string;
  EUsers?: number;
  CUsers?: number;
  AFRUsers?: number;
  basePrice?: number;
  purchasePrice?: number;
  originalPurchasePrice?: number;
  trainingDate?: Date;
  trainer?: string;
  originalMaintValue?: number;
  originalMaintAmount?: number;
  maintTypeID?: number;
  installedVersion: string;
  keys?: string;
  PMPA?: string;
  SWPDCount?: number;
  SWPACount?: number;
  SWPDKeys?: string;
  SWPAKeys?: string;
  OEMOption?: string;
  maintPct?: number;
  //
  OemId?: number;
  distributorLoboPath: string;
  goldmineUid: string;
  legacyMseCompanyCode: number;
  bobCadUid: number;
  bobCadUpsellFlag: boolean;
  protectedCustomer: boolean;
  latitude: number;
  longitude: number;
  geoCoded: boolean;
  lastQuoteId: number;
  website?: string;
  notes?: string;
  active: boolean;
  customerTypeId: number;
}

export const DefaultCompanyId = '000000000000000000000000';

export const EmptyCompanyPublicInfo: ICompanyPublicInfo = {
  id: '',
  name: '',
  logo: '',
  logoPrint: '',
  footerPrint: '',
  favicon: '',
  domain: '',
  signupAllowed: false,
  signupRole: '',
  laborCodes: [cloneDeep(EmptyBusinessCosts)],
  defaultMarkups: [cloneDeep(EmptyProfit)],
  links: {
    privacyPolicy: { title: '', src: '' },
    termsOfService: { title: '', src: '' },
    termsAndConditions: { title: '', src: '' },
    homePage: { title: '', src: '' },
    supportPage: { title: '', src: '' },
  },
  mailingAddress: {
    recipientName: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    country: ''
  },
};
Object.freeze(EmptyCompanyPublicInfo);

export const EmptyCompany: ICompany = {
  name: '',
  logo: '',
  logoPrint: '',
  footerPrint: '',
  favicon: '',
  domain: '',
  syncCompanyID: null,
  signupAllowed: false,
  signupRole: '',
  laborCodes: [cloneDeep(EmptyBusinessCosts)],
  defaultMarkups: [cloneDeep(EmptyProfit)],
  links: {
    privacyPolicy: { title: '', src: '' },
    termsOfService: { title: '', src: '' },
    termsAndConditions: { title: '', src: '' },
    homePage: { title: '', src: '' },
    supportPage: { title: '', src: '' },
  },
  apiKeys: {
    stripePublicKey: '',
  },
  stripeCustomerId: '',
  primaryContact: '',
  mailingAddress: {
    recipientName: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
  },
};
Object.freeze(EmptyCompany);

// export const toCompanyPublicInfo = (input: Partial<ICompanyPublicInfo>): ICompanyPublicInfo => {
//   const publicInfo = fill(EmptyCompanyPublicInfo, input);
//   return publicInfo;
// }
