import { ActionReducerMap } from '@ngrx/store';
import { IRouterState, reducer as routerReducer } from './router.reducer';

export * from './customSerializer';
export * from './routerStoreExtension';

export interface IAppState {
  router: IRouterState;
}

export const reducers: ActionReducerMap<IAppState> = {
  router: routerReducer,
};
