import { IBusinessCompany, IBusinessUser, IUserInfo, IBusinessPayment } from '.';
import { IBusinessMessage, IBusinessMessageBoard } from './messages';
import { INewPart } from './parts';
import { INewCost } from './cost';
import { IWork } from './work';
import { IAddress } from './address';

export interface Status {
  label: string;
  progress: number;
  rejectReason?: string;
  rejectComments?: string;
}

export interface IQuoteStatus {
  label: string;
  progress: number;
  rejectReason?: string;
  rejectComments?: string;
}

// export interface IAddress {
//   streetAddress: string;
//   city: string;
//   state: string;
//   zipCode: string;
//   country: string;
// }

export interface IQuoteAddress {
  shipping: IAddress | null;
  billing: IAddress | null;
}

export interface IQuoteInfo {
  name: string;
  buyer: string;
  buyerData?: any;
  buyerCompany?: any;
  status: IQuoteStatus;
  quantity: number;
  leadTime: string;
  price: number;
  lastModified: Date;
  cancelled?: boolean;
  refQuote?: string;
  seller: string;
  sellerCompany?: string;
  parts?: any[];
}

export interface IBusinessQuoteInfo extends IQuoteInfo {
  id: string;
}

export interface IUpdatableMainQuote {
  info?: {
    name: string;
  };
  addresses?: IQuoteAddress;
}

export interface IBusinessUpdatedMainQuote {
  id: string;
  info: IBusinessQuoteInfo;
  addresses: IQuoteAddress;
}

export interface INewQuote {
  id?: any;
  buyer: string;
  buyerData?: any;
  buyerCompany?: any;
  name: string;
  status: IQuoteStatus;
  quantity: number;
  leadTime: string;
  price: number;
  lastModified: Date;
  cancelled?: boolean;
  deleted?: boolean;
  reference: string;
  created: Date;
  legal: string;
  addresses: IQuoteAddress;
  parts: INewPart[];
  works: IWork[];
  messageBoards: any[];
  payments?: any[];
  seller: string;
  sellerCompany?: IBusinessCompany;
  refQuote?: string;
  autoQuote: boolean;
  cost: number;
  additionalCost: number;
  // totalCost: number;
  // totalAdditionalCost: number;
}

export interface IBusinessQuote {
  id: string;
  parts: INewPart[];
  info: IQuoteInfo;
  details: {
    reference: string;
    created: Date;
    legal: string;
  };
  addresses: IQuoteAddress;
  messageBoards: IBusinessMessageBoard[];
  payments: IBusinessPayment[];
  refQuote?: string;
}

export interface IUserRelatedQuote {
  userInfo: IUserInfo;
  quoteId: string;
}

export interface IQuotePriceRelated {
  id: string;
  info: { quantity: number };
  parts: INewPart[];
}

export class QuoteStatus implements Status {
  // private to disallow creating other instances of this type
  private constructor(public readonly label: string, public readonly progress: any) {}
  public static readonly CANCELLED = new QuoteStatus('Cancelled', 0);
  public static readonly OPEN = new QuoteStatus('Open', 0);
  public static readonly DRAFT = new QuoteStatus('Draft', 0);
  public static readonly ESTIMATE = new QuoteStatus('Estimate', 0);
  public static readonly HIDDEN = new QuoteStatus('Hidden', 0);
  public static readonly ARCHIVED = new QuoteStatus('Archived', 0);
  public static readonly DELETED = new QuoteStatus('Deleted', 0);
  public static readonly NO_QUOTE = new QuoteStatus('No Quote', 0);
  public static readonly RFQ = new QuoteStatus('Request for Quote', 1);
  public static readonly PENDING = new QuoteStatus('Pending Quote', 2);
  public static readonly QUOTED = new QuoteStatus('Quoted', 3);
  public static readonly APPROVED_BY_CUSTOMER = new QuoteStatus('Approved', 4);
  public static readonly REJECTED_BY_CUSTOMER = new QuoteStatus('Rejected', 4);
  public static readonly WAITING_FOR_PAYMENT = new QuoteStatus('Waiting for Payment', 5);
  public static readonly PURCHASED = new QuoteStatus('Purchased', 6);

  public static fromString(label: string): QuoteStatus | undefined {
    switch (label) {
      case 'Cancelled':
        return QuoteStatus.CANCELLED;
      case 'Open':
        return QuoteStatus.OPEN;
      case 'Draft':
        return QuoteStatus.DRAFT;
      case 'Estimate':
        return QuoteStatus.ESTIMATE;
      case 'Hidden':
        return QuoteStatus.HIDDEN;
      case 'Archived':
        return QuoteStatus.ARCHIVED;
      case 'Deleted':
        return QuoteStatus.DELETED;
      case 'No Quote':
        return QuoteStatus.NO_QUOTE;
      case 'Request for Quote':
        return QuoteStatus.RFQ;
      case 'Pending Quote':
        return QuoteStatus.PENDING;
      case 'Quoted':
        return QuoteStatus.QUOTED;
      case 'Waiting for Payment':
        return QuoteStatus.WAITING_FOR_PAYMENT;
      case 'Purchased':
        return QuoteStatus.PURCHASED;
      case 'Approved':
        return QuoteStatus.APPROVED_BY_CUSTOMER;
      case 'Rejected':
        return QuoteStatus.REJECTED_BY_CUSTOMER;
      default:
        return undefined;
    }
  }

  public static GetEveryStatus() {
    return Object.keys(QuoteStatus).map((key) => QuoteStatus[key].toString());
  }

  public toString() {
    return this.label;
  }
}

export interface MainQuote {
  name: string;
  status: Status;
  quantity: string;
  leadTime: string;
  price: number;
  lastModified?: Date;
  owner: string;
  legal?: string;
  shippingAddress?: string;
  billingAddress?: string;
}

export interface Quote extends MainQuote {
  job: string;
}

export interface QuoteHeaders {
  id: string;
  leadTime: string;
  name: string;
  price: string;
  quantity: number;
  status: QuoteInfoStatus;
}

export interface PaginatedQuotes {
  quotes: QuoteHeaders[];
  count: number;
}

export interface IFrontEndBusinessQuote {
  id: string;
  date: Date;
  leadTime: string;
  name: string;
  price: string;
  quantity: number;
  status: QuoteInfoStatus;
  lastModified?: Date;
  addresses: Addresses;
  created: Date;
  legal: string;
  reference: string;
  seller: string;
  sellerCompany: QuoteSeller;
  //details: QuoteDetails;
  //info: QuoteInfo;
  messageBoards: QuoteMessageBoard[];
  salesTeam: IBusinessUser[];
  buyer: IBusinessUser;
  parts: INewPart[];
  autoQuote: boolean;
  works: IWork[];
}

export interface IFrontEndBusinessQuoteExtended extends Omit<IFrontEndBusinessQuote, 'buyer'> {
  buyer?: any;
  buyerData?: any;
  buyerCompany?: any;
  refQuote?: any;
}

export interface Addresses {
  billing: Address;
  shipping: Address;
}

export interface Address {
  city: string;
  country: string;
  state: string;
  streetAddress: string;
  zipCode: string;
}

export interface QuoteInfoStatus {
  label: string;
  progress: number;
  rejectReason?: string;
  rejectComments?: string;
}

export interface QuoteMessageBoard {
  name: string;
  created: Date;
  id: string;
  messages: IBusinessMessage[];
}

export interface QuoteRequest {
  date: Date;
  leadTime: string;
  name: string;
  price: string;
  quantity: number;
  status: QuoteInfoStatus;
  lastModified?: Date;
  created: Date;
  legal: string;
  reference: string;
  addresses: Addresses;
  parts: INewPart[];
}

export interface QuoteDetailUpdateResponse {
  id: string;
  info: {
    status: {
      label: string;
      progress: number;
      rejectReason?: string;
      rejectComments?: string;
    };
    name: string;
    quantity: number;
    leadTime: string;
    lastModified: Date;
    cancelled: boolean;
  };
  addresses: Addresses;
}

export interface QuoteDetailUpdateRequest {
  quote: {
    info: {
      name: string;
    };
    addresses: Addresses;
  };
}

export interface QuoteSeller {
  laborCodes: QuoteLaborCode[];
}

export interface QuoteLaborCode {
  companyId: string;
  laborCode: string;
  workDescription: string;
  machineStyle: string;
  costs?: INewCost[];
}
