export interface IProfit {
    process: string;
    costType: string;
    markupName: string;
    markup: number;
}

export const EmptyProfit: IProfit = {
    process: '',
    costType: '',
    markupName: '',
    markup: null,
}
Object.freeze(EmptyProfit);