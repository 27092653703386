import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.interface';
import { environment } from '@zc/environment';

@Injectable()
export class HttpApiService implements ApiService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  get<T>(endpoint: string, params?: GetParams): Observable<T> {
    return this.http.get<T>(`${this.baseUrl}${endpoint}`, {
      headers: this.headers,
      params,
    });
  }

  post<T>(endpoint: string, data: Object = {}): Observable<T> {
    return this.http.post<T>(`${this.baseUrl}${endpoint}`, data, { headers: this.headers });
  }

  put<T>(endpoint: string, data: Object = {}): Observable<T> {
    return this.http.put<T>(`${this.baseUrl}${endpoint}`, data, { headers: this.headers });
  }

  patch<T>(endpoint: string, data: Object = {}): Observable<T> {
    return this.http.patch<T>(`${this.baseUrl}${endpoint}`, data, { headers: this.headers });
  }

  delete<T>(endpoint: string): Observable<T> {
    return this.http.delete<T>(`${this.baseUrl}${endpoint}`, { headers: this.headers });
  }

  get headers(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      timeout: JSON.stringify(environment.timeout + 10000),
    };

    return new HttpHeaders(headersConfig);
  }
}

type GetParams = HttpParams | { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> };
