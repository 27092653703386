import { HttpEvent, HttpHandler, HttpRequest, HttpInterceptor } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';
import { environment } from '@zc/environment';

import { LocalStorageService, LocalStorageKeys } from '../local-storage-jwt.service';

const HttpVerbsWithBody = ['post', 'put', 'patch'];

@Injectable()
export class ActiveDomainInterceptorService implements HttpInterceptor {
  constructor(private localStorageService: LocalStorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.localStorageService.getItemObs(LocalStorageKeys.activeDomain).pipe(
      take(1),
      switchMap((activeDomain) => {
        if (activeDomain && request.url.includes(environment.baseUrl)) {
          if (HttpVerbsWithBody.includes(request.method.toLowerCase())) {
            return next.handle(request.clone({ body: { ...request.body, activeDomain } }));
          }
          const params = request.params.set('activeDomain', activeDomain);
          return next.handle(request.clone({ params }));
        }
        return next.handle(request);
      }),
    );
  }
}
