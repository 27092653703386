import { IBusinessUser } from '.';
import { QuoteMessageBoard } from './quote';

export interface IMainMessageBoard {
  name: string;
  created: Date;
  class: String;
}

export interface IMessageBoard extends IMainMessageBoard {
  participants: string[];
  messages: string[];
}

export interface IBusinessMessageBoard extends IMainMessageBoard {
  id: string;
  participants: IBusinessUser[];
  messages: IBusinessMessage[];
}

export interface IMessage {
  sender: IBusinessUser;
  text: string;
  date: Date;
}

export interface IBusinessMessage extends IMessage {
  id: string;
}

export interface IReadyMessage {
  sender: string;
  text: string;
}

export interface IMainBusinessMessageBoard {
  data: QuoteMessageBoard;
}

export interface IBusinessQuoteMessageBoards {
  quoteId: string;
  messageBoards: IBusinessMessageBoard[];
}
