import {
  DimensionUnits,
  IEstimate,
  INewStock,
  INewWorkflowLayout,
  IPricedWorkflow,
  IResults,
  IStock,
  IWork,
  WorkType,
} from '..';

export interface IMainPartDetails {
  name: string;
  volume: number;
  area: number;
  x: number;
  y: number;
  z: number;
}

export interface IPartDetails extends IMainPartDetails {
  stock: string;
  material: string;
}

export interface IUploadablePartDetails extends IMainPartDetails {
  material: string;
  stock: IStock;
}
export interface INewPartDetails extends IMainPartDetails {
  material: string;
  stock: string;
}

export interface MainPart {
  details: IMainPartDetails;
}
export interface IPart extends MainPart {
  details: IPartDetails;
  // cost: string;
  work: string;
  quantity: number;
  files: string[];
}

export interface INewPart {
  _id?: any;
  id?: any;
  name: string;
  volume: number;
  area: number;
  x: number;
  y: number;
  z: number;
  units: DimensionUnits;
  stock: INewStock;
  material: any;
  quantity: number;
  alternateQuantities?: IAlternateQuantitiesDetailed[];
  work: IWork[];
  workflows: INewWorkflowLayout[];
  layouts: IPricedWorkflow[];
  calculationResults: IResults[] | null;
  files: any[];
  processName: WorkType;
  castDirection: any;
  estimates: IEstimate[];
  runtime: number;
  setupTime: number;
  loadTime: number;
  cycleTime: number;
  cost: number;
  additionalCost: number;
  totalRunTime: number;
  totalCost: number;
  totalAdditionalCost: number;
}

export interface IAlternateQuantities {
  quantity: number;
}

export interface IAlternateQuantitiesDetailed extends IAlternateQuantities {
  runtime?: number;
  cost?: number;
  additionalCost?: number;
  totalCost?: number;
  totalAdditionalCost?: number;
}
