import { fill } from 'libs/util/src/lib/fill';
import cloneDeep from 'lodash/cloneDeep';
import { Types } from 'mongoose';

export enum CostStyle {
  Complex = 'Complex',
  FlatRate = 'Flat Rate',
  Percentage = 'Percentage',
  Hourly = 'Hourly',
  Runtime = 'Run Time',
  Setup = 'Setup',
}

export interface CostFlag {
  amortized: boolean;
  oneTimeCost: boolean;
}

export enum CostType {
  ENGINEERING = 'ENGINEERING',
  LABOR = 'LABOR',
  SETUP = 'SETUP',
  OVERHEAD = 'OVERHEAD',
  TOOLING = 'TOOLING',
  PURCHASE_ITEMS = 'PURCHASE_ITEMS',
  MATERIAL = 'MATERIAL',
  BURDEN = 'BURDEN',
}

export enum UnitOfSale {
  PERPART = 'PERPART',
  PERUNIQUEPART = 'PERUNIQUEPART',
  SINGLE = 'SINGLE',
  TOTALCOST = 'TOTALCOST',
}

export interface IPrice {
  cost: number;
  additionalCost: number;
}

export interface IBusinessCosts extends ILaborCode {
  id: string;
}

export enum CostPercentType {
  Multiplicative = 'Multiplicative',
  Additive = 'Additive',
}

export interface ICostPercent {
  percentType: CostPercentType;
  percent: number;
}

export interface INewCost {
  _id?: Types.ObjectId;
  id?: string;
  name: string;
  minimum?: number;
  maximum?: number;
  rate?: number;
  efficiencyRate?: number;
  flatRate?: number;
  hours?: number;
  amortized: boolean;
  oneTimeCost?: boolean;
  costPercent?: ICostPercent;
  unitOfSale: UnitOfSale;
  costType: CostType;
  style: CostStyle;
  // cost?: number;
  // additionalCost?: number;
  price?: number; // is this the same as cost?
  visible: boolean;
}
export interface WorkflowCost {
  cycleTime?: number;
  grossProduction?: number;
  loadTime?: number;
  setupTime?: number;
  totalCutTime?: number;
  totalIdleTime?: number;
}

export interface IFlatRateCost {
  id?: string;
  name: string;
  flatRate: number;
  unitOfSale: UnitOfSale;
  costType: CostType;
  style: CostStyle;
  visible: boolean;
}
export interface ILaborCode {
  companyId: string;
  laborCode: string;
  workDescription: string;
  machineStyle: string;
  costs?: INewCost[];
}

export const EmptyNewCost: INewCost = {
  id: '',
  name: '',
  minimum: null,
  maximum: null,
  rate: null,
  efficiencyRate: null,
  flatRate: null,
  hours: null,
  amortized: true,
  oneTimeCost: false,
  costPercent: {
    percentType: CostPercentType.Multiplicative,
    percent: null,
  },
  unitOfSale: UnitOfSale.PERPART,
  costType: CostType.SETUP,
  style: CostStyle.Complex,
  // cost: null,
  // additionalCost: null,
  price: null, // is this the same as cost?
  visible: false,
};
Object.freeze(EmptyNewCost);

export const EmptyBusinessCosts: IBusinessCosts = {
  id: '',
  companyId: '',
  laborCode: '',
  workDescription: '',
  machineStyle: '',
  costs: [cloneDeep(EmptyNewCost)],
};
Object.freeze(EmptyBusinessCosts);

export const toLaborCode = (input: Partial<IBusinessCosts>): IBusinessCosts => {
  return fill(EmptyBusinessCosts, input);
};
