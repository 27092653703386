import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IRouterState } from './reducers/router.reducer';

export const selectRouter = createFeatureSelector<IRouterState>('router');
export const getState = createSelector(
  selectRouter,
  state => state.state,
);
export const getCurrentRoute = createSelector(
  selectRouter,
  state => state.activeRoute,
);
export const getPreviousRoute = createSelector(
  selectRouter,
  state => state.previousRoute,
);

export const routerQuery = {
  getState,
  getCurrentRoute,
  getPreviousRoute,
};
