export class Alert {
  id = '';
  type: AlertType = AlertType.Success;
  message = '';
  timeout?: number | undefined = undefined;
  keepAfterRouteChange? = false;

  constructor(init?: Partial<Alert>) {
    Object.assign(this, init);
  }
}

export enum AlertType {
  Success = 'success',
  Error = 'danger',
  Info = 'info',
  Warning = 'warning',
}
