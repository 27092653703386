import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ALERT_FEATURE_KEY, State, alertAdapter } from './alert.reducer';

// Lookup the 'Alert' feature state managed by NgRx
export const getAlertState = createFeatureSelector<State>(ALERT_FEATURE_KEY);

const { selectAll, selectEntities } = alertAdapter.getSelectors();

export const getAllAlerts = createSelector(getAlertState, (state: State) =>
  selectAll(state)
);

export const getAlertEntities = createSelector(getAlertState, (state: State) =>
  selectEntities(state)
);

export const getSelectedId = createSelector(
  getAlertState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getAlertEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
