export enum CalcResultCategories {
    perUnit = 'Per Unit',
    totals = 'Totals',
    jobCosts = 'Job Costs',
    quote = 'Quote',
    printout = 'printout',
    // casting categories...
    tooling = 'Tooling',
    setup = 'Setup',
    perPart = 'Per Part',
    grandTotal = 'Grand Total',
  }

export enum CalcResultValues {
    unitRunTime= 'unit-run-time',
    unitSetupTime='unit-setup-time',
    unitCost= 'unit-cost',
    totalCost= 'total-cost',
    totalAdditionalCost= 'total-additional-cost',
    // job cost ...
    jobTotalPartPrice = 'job-total-part-price',
    jobTotalPrice = 'job-total-price',
    // quote ....
    quoteTotalPrice = 'quote-total-price',
    // casting values...
    baseTooling = 'base-tooling',
    coreTooling = 'core-tooling',
    totalTooling = 'total-tooling',
    shopSetup = 'shop-setup',
    unitAdditionalCost = 'unit-additional cost',
    unitTotal= 'unit-total',
    grandTotal = 'grand-total',
  }