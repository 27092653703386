import { IBusinessOperation, INewOperation } from './operation';
import { VariableType } from './item';
import { IBusinessUserInfo } from './user';

export interface INewFeature {
  id: string;
  name: string;
  style: string;
  quantity: number;
  operations: INewOperation[];
  sharedParameters: VariableType<string | number | boolean | null>[];
  runtime: number;
}

export interface IBusinessFeature {
  id: string;
  operations: IBusinessOperation[];
  name: string;
  style: string;
  quantity: number;
  sharedParameters?: VariableType<string | number | boolean | null>[];
}

export enum FeatureType {
  GROUP = 'GROUP',
  HOLE = 'HOLE',
  POCKET = 'POCKET',
  LATHE = 'LATHE',
  EDGE = 'EDGE',
}

export interface IFeature {
  name: string;
  style: string;
  quantity: number;
  sharedParameters: VariableType<string | number | boolean | null>[];
  operations: string[];
  owner: string;
  type: string;
}

export interface IUserFeature extends IFeature {
  operations: any[];
  user: string;
}

export interface IBusinessUserFeature extends IBusinessFeature {
  user: IBusinessUserInfo;
}

export interface IAnalyzerFeatures {
  Pockets?: any[];
  Holes?: any[];
  Threads?: any[];
  Turning?: string;
  SharpEdges?: any;
  volume?: number;
  boundingBox?: any;
  scale?: string;
}
