import { Types } from 'mongoose';
import { IBusinessLicenseLevel, IUserRole, LicenseLevelType } from '.';

export interface ILicenseLevelUsage {
  uOpCredits?: number;
  namedUsers?: number;
  productId?: string;
}

export interface ILicenseLevelUsageSummary {
  uOpCredits?: number;
  namedUsers?: number;
  products?: number;
}

export interface ILicenseShouldCostInfo {
  roles: IUserRole[];
  usage?: ILicenseLevelUsage;
}

export interface ILicense {
  name: string;
  description: string;
  user: string | null | undefined;
  buyer: string;
  startDate: Date;
  expireDate: Date;
  plan: string | null | undefined;
  level: string;
  stripeSubscriptionId: string | null | undefined;
  status: string;
  type: LicenseLevelType;
  roles?: IUserRole[];
  shouldCostInfo?: ILicenseShouldCostInfo;
  mesInfo?: {
    precisionOptionItemId?: string;
    precisionOptionTypeId?: number;
    precisionOptionType?: string;
    precisionPrice?: number;
    mseId?: string;
    isPackage: boolean;
    isOneTimeCharge: boolean;
  };
  importedMesOptionInfo?: {
    importedMesPlanId?: string;
    precisionQuoteId?: number;
    subTotal?: number;
    discount?: number;
    quotedTotal?: number;
  };
  purchasingUser: any;
}

export interface IBusinessLicense {
  _id?: Types.ObjectId;
  id?: string;
  name: string;
  description: string;
  user: string | object;
  buyer: string;
  startDate: Date;
  expireDate: Date;
  plan: string | object;
  level: string;
  stripeSubscriptionId: string;
  status: string;
  type: LicenseLevelType;
  shouldCostInfo?: ILicenseShouldCostInfo;
  mesInfo?: any;
  importedMesOptionInfo?: any;
  purchasingUser: any;
}

export interface IBusinessLicenseWithLevel {
  _id?: Types.ObjectId;
  id?: string;
  name: string;
  description: string;
  user: string | object;
  buyer: string;
  startDate: Date;
  expireDate: Date;
  plan: string | object;
  level: IBusinessLicenseLevel;
  stripeSubscriptionId: string;
  status: string;
  type: LicenseLevelType;
  shouldCostInfo?: ILicenseShouldCostInfo;
  mesInfo?: any;
  importedMesOptionInfo?: any;
  purchasingUser: any;
}

export interface ILicenseSummary {
  id: string;
  name: string;
  description: string;
  status: string;
  numAvailable: number;
  numTotal: number;
  stripeSubscriptionId: string;
  levelRoles: IUserRole[];
  levelUsage: ILicenseLevelUsage;
  licenses?: any[];
}

export interface ISubscription {
  startDate: number;
  expireDate: number;
  status: string;
  type: LicenseLevelType;
  levels: IBusinessLicense[];
}

export interface ISubscriptionStripe extends ISubscription {
  amount: number;
  intervalType: string;
  intervalCount: number;
  stripeSubscriptionId: string;
  autoRenew: boolean;
  billingMethod: string;
  trialStartDate?: number;
  trialEndDate?: number;
}
