import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { delay, map, tap } from 'rxjs/operators';
import { NavigateTo, RouterActionTypes } from './app-router.actions';

@Injectable()
export class RouterEffects {
  constructor(private action$: Actions, private router: Router) {}

  
  navigateTo$ = createEffect(() => this.action$.pipe(
    ofType<NavigateTo>(RouterActionTypes.NAVIGATE_TO),
    map(action => action.payload),
    delay(0),
    tap(({ url, extras }) => {
      if (Array.isArray(url)) {
        return this.router.navigate(url, extras);
      }
      return this.router.navigateByUrl(url, extras);
    }),
  ), { dispatch: false });
}
