import { UserRole } from '../enumerations/user-role';

export interface IPrivilege {
    name: string,
    limit?: number
}

export interface IRolePrivileges {
        role: UserRole;
        companyId?: string;
        privileges: IPrivilege[];

}

export interface IBusinessRolePrivileges extends IRolePrivileges {
    id: string;
}
