export interface IPaginationOptions {
  page: number;
  limit: number;
  statusFilter: string;
  clientFilter: string;
  searchFilter: string;
  sortKey: string;
  sortAsc: boolean;
  domain?: string;
}

export class PaginationOptions implements IPaginationOptions {
  public page = 1;
  public limit = 25;
  public statusFilter = '';
  public clientFilter = '';
  public searchFilter = '';
  public sortKey = '';
  public sortAsc = true;
  public domain = '';

  public constructor(options: IPaginationOptions) {
    // console.debug('init pagination opts', options);
    this.page = options.page || 1;
    this.limit = options.limit || 25;
    this.statusFilter = options.statusFilter || '';
    this.clientFilter = options.clientFilter || '';
    this.searchFilter = options.searchFilter || '';
    this.sortKey = options.sortKey || 'lastModified';
    this.sortAsc = options.sortAsc || true;
    this.domain = options.domain || '';
  }

  public toString(): string {
    const options = this.getOptions();
    options.domain = this.domain;
    return JSON.stringify(options);
  }

  public buildQueryString(): string {
    const options = this.getOptions();

    let query = '?';
    Object.keys(options).forEach(key => {
      query += options[key] !== undefined ? `${key}=${options[key]}&` : '';
    });

    return query;
  }

  private getOptions():IPaginationOptions {
    return {
      page: this.page - 1,
      limit: this.limit,
      statusFilter: this.statusFilter,
      clientFilter: this.clientFilter,
      searchFilter: this.searchFilter,
      sortKey: this.sortKey,
      sortAsc: this.sortAsc,
    };
  }
}
