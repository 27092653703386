export const E_TrialTrackingType = {
  BETA: 'BETA',
};
export const E_TrialTrackingBetaKey = {
  LITE: 'LITE',
  STANDARD: 'STANDARD',
  PROFESSIONAL: 'PROFESSIONAL',
};

export interface ITrialTrackingRequest {
  company: string;
  user: string;
  type: string;
  key: string;
}

export interface IBusinessTrialTrackingRequest extends ITrialTrackingRequest {
  id: string;
}
