import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertFacade } from '../+state/alert/alert.facade';
import { Alert, AlertType } from './alert.model';

@Component({
  selector: 'mes-license-alert',
  templateUrl: 'alert.component.html',
})
export class AlertComponent implements OnInit, OnDestroy {
  @Input() id = 'default-alert';

  alerts$ = this.alertFacade.allAlerts$;
  routeSubscription: Subscription = new Subscription();

  constructor(private router: Router, private alertFacade: AlertFacade) {}

  ngOnInit() {
    // clear alerts on location change
    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.alertFacade.clear(this.id);
      }
    });
  }

  onClosed(alert: Alert) {
    console.log('Closing');
    this.alertFacade.remove(alert);
  }

  ngOnDestroy() {
    // unsubscribe to avoid memory leaks
    this.routeSubscription.unsubscribe();
  }

  cssClass(alert: Alert) {
    if (!alert) return;

    const classes = ['alert', 'alert-dismissable'];

    const alertTypeClass = {
      [AlertType.Success]: 'alert-success',
      [AlertType.Error]: 'alert-danger',
      [AlertType.Info]: 'alert-info',
      [AlertType.Warning]: 'alert-warning',
    };

    classes.push(alertTypeClass[alert.type]);

    return classes.join(' ');
  }
}
