import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ApiServiceModule } from '@zc/api-service';
import { isMock } from '@zc/environment';
import { AuthEffects } from './+state/auth.effects';
import { AuthFacade } from './+state/auth.facade';
import { authReducer, initialState } from './+state/auth.reducer';
import { HttpAuthService } from './auth.service';
import { MockAuthService } from './mock.auth.service';
import { NgHttpLoaderModule } from 'ng-http-loader';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    StoreModule.forFeature('auth', authReducer, { initialState }),
    EffectsModule.forFeature([AuthEffects]),
    ApiServiceModule,
  ],
  providers: [
    AuthEffects,
    AuthFacade,
    { provide: HttpAuthService, useClass: isMock ? MockAuthService : HttpAuthService },
  ],
  declarations: [],
})
export class AuthModule {}
