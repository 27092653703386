import * as loadedConvict from '!val-loader!./convict-loader.js';

export interface IConvict {
  env: 'production' | 'development' | 'mock' | 'local';
  baseUrl: string;
  stripeKey: string;
  stripeApiVersion: string;
  defaultDomainId: string;
  timeout: number;
}

export const environment = loadedConvict as unknown as IConvict;

export const isProd = environment.env === 'production';
export const isMock = environment.env === 'mock';
