export interface ITool {
  name: string;
  manufacturer: string;
  diameter: number;
  cuttingSurface: number;
  material: string;
  moreDetails: unknown;
}

export interface IBusinessTool extends ITool {
  id: string;
}
