import * as changeCase from 'change-case';

import { VariableType } from '@zc/api';

export const transformSingleObject = (obj: {
  [key: string]: string | VariableType<string | number | boolean | null | number[] | string[]>;
}) => {
  const returnObject = {};
  Object.keys(obj).map((key) => {
    if (key !== 'label') {
      (returnObject as any)[(obj[key] as any).label] = {
        value: (obj[key] as any).value,
        options: (obj[key] as any).options,
        rows: (obj[key] as any).rows,
        columns: (obj[key] as any).columns,
        values: (obj[key] as any).values,
      };
    }
  });
  Object.keys(returnObject).map((key) => {
    if (((returnObject as any)[key] as any).value === undefined) {
      delete ((returnObject as any)[key] as any).value;
    }
    if (((returnObject as any)[key] as any).options === undefined) {
      delete ((returnObject as any)[key] as any).options;
    }
    if (((returnObject as any)[key] as any).rows === undefined) {
      delete ((returnObject as any)[key] as any).rows;
    }
    if (((returnObject as any)[key] as any).columns === undefined) {
      delete ((returnObject as any)[key] as any).columns;
    }
    if (((returnObject as any)[key] as any).values === undefined) {
      delete ((returnObject as any)[key] as any).values;
    }
    if (
      ((returnObject as any)[key] as any).value !== undefined &&
      ((returnObject as any)[key] as any).options === undefined
    ) {
      ((returnObject as any)[key] as any) = ((returnObject as any)[key] as any).value;
    }
  });
  return returnObject;
};

export const transformObject = async (obj: MoreDetailsType) => {
  const returnObject = {};
  Object.keys(obj).map((key) => {
    if (obj[key]) {
      const singleObject = transformSingleObject(obj[key]);
      (returnObject as any)[(obj[key] as any).label] = singleObject;
    }
  });
  return returnObject;
};

export const transformVariableObject = (obj: MoreDetailsType) => {
  const returnObject = {};
  // logger.debug({ message: obj });
  Object.keys(obj).map((key) => {
    if (obj[key].value && obj[key].options) {
      (returnObject as any)[(obj[key] as any).label] = {
        value: obj[key].value,
        options: obj[key].options,
      };
    } else {
      (returnObject as any)[(obj[key] as any).label] = obj[key].value;
    }
  });
  return returnObject;
};
// for simple {"label": "value"} => {[key]:{label: "label", value: "value"}}
// what becomes the key?
// the key becomes the label
// the value become the value
// for options {"label": {value: "value", options: ["options"]}} =>
// {[key]:{label: "label", value: "value", options: ["options"]}}

export const reformatOperationVariables = (operation: any) => {
  const variableKeys = Object.keys(operation.variables);
  const operationVariables: {
    [key: string]: VariableType<string | number | boolean | string>;
  } = {};
  variableKeys.forEach((key) => {
    const camelCaseKey = changeCase.camelCase(key);
    if ((operation.variables as any)[key] !== null) {
      switch (typeof (operation.variables as any)[key]) {
        case 'object': {
          operationVariables[camelCaseKey] = {
            label: key,
            value: (operation.variables as any)[key].value,
            options: (operation.variables as any)[key].options,
          };
          break;
        }
        case 'string': {
          operationVariables[camelCaseKey] = {
            label: key,
            value: (operation.variables as any)[key],
          };
          if (!isNaN(parseInt((operation.variables as any)[key], 16))) {
            (operationVariables as any)[camelCaseKey].value = parseInt(
              (operationVariables as any)[camelCaseKey].value,
              16,
            );
          }
          break;
        }
        default:
          operationVariables[camelCaseKey] = {
            label: key,
            value: (operation.variables as any)[key],
          };
          break;
      }
    } else {
      (operationVariables as any)[camelCaseKey] = {
        label: key,
        value: null,
      };
    }
  });
  (operation as any).variables = operationVariables;
  return operation;
};

export interface MoreDetailsType {
  [key: string]: { [key: string]: string | VariableType<string | number | boolean | null | number[] | string[]> };
}
