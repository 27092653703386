
export interface INote {
  text: string,
  subjectId: string,
  ownerCompanyId: string,
  created?: Date,
  owner?: string,
}

export interface IBusinessNote extends INote {
  id: string;
}