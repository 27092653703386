import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { AlertComponent } from './alert/alert.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromAlert from './+state/alert/alert.reducer';
import { AlertEffects } from './+state/alert/alert.effects';
import { AlertFacade } from './+state/alert/alert.facade';
import { SnackbarComponent } from './snackbar/snackbar.component';

@NgModule({
  imports: [
    AlertModule.forRoot(),
    CommonModule,
    StoreModule.forFeature(fromAlert.ALERT_FEATURE_KEY, fromAlert.reducer),
    EffectsModule.forFeature([AlertEffects]),
  ],
  declarations: [AlertComponent, SnackbarComponent],
  exports: [AlertComponent, SnackbarComponent],
  providers: [AlertFacade],
})
export class UiAlertModule {}
