import { NavigationExtras } from '@angular/router';
import { Action } from '@ngrx/store';

export enum RouterActionTypes {
  ROUTER_ACTIVE = '[Router] Route Activated',
  NAVIGATE_TO = '[Router] Navigate to url',
}

export class RouterActive implements Action {
  readonly type = RouterActionTypes.ROUTER_ACTIVE;
  constructor(public payload: any) {}
}

export class NavigateTo implements Action {
  readonly type = RouterActionTypes.NAVIGATE_TO;
  constructor(public payload: { url: string | (string | number)[]; extras?: NavigationExtras }) {}
}

export type RouterAction = RouterActive | NavigateTo;
