export interface IEmailService {
  createAndSendEmail(options: {
    templateName: string;
    model: any;
    subject: string;
    to: string;
    sender: string;
    cc?: string;
  }): Promise<any>;
  sendMail(mailOptions: any): Promise<any>;
}
