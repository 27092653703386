import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import { filter, withLatestFrom } from 'rxjs/operators';
import { IAppState } from '.';
import { RouterActive } from '../app-router.actions';
import { getRouterState, RouterStateUrl } from './router.reducer';

@Injectable()
export class RouterStoreExtension {
  protected lastRoutesRecognized: RoutesRecognized = null;

  constructor(router: Router, stateSerializer: RouterStateSerializer<RouterStateUrl>, store: Store<IAppState>) {
    router.events.pipe(filter<RoutesRecognized>(event => event instanceof RoutesRecognized)).subscribe(e => {
      this.lastRoutesRecognized = e;
    });

    router.events
      .pipe(
        filter<NavigationEnd>(event => event instanceof NavigationEnd),
        withLatestFrom(store.pipe(select(getRouterState))),
        // this prevents the dispatch of ROUTE_ACTIVE when the state was changed by dev tools
        filter(([event, routerReducer]) => routerReducer['navigationId'] === event.id),
      )
      .subscribe(args => {
        const routerStateSer: any = stateSerializer.serialize(router.routerState.snapshot);
        store.dispatch(
          new RouterActive({
            routerState: routerStateSer,
            previousRoute: args[1].activeRoute,
            event: new RoutesRecognized(
              this.lastRoutesRecognized.id,
              this.lastRoutesRecognized.url,
              this.lastRoutesRecognized.urlAfterRedirects,
              routerStateSer,
            ),
          }),
        );
      });
  }
}
