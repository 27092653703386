import { IOperationRunResults } from './operation';

export interface IMicroServiceResponse {
  updatedOps: [
    {
      name: string;
      owner: string;
      style: string;
      runtime: number;
      varibles: object;
      id: string;
      quantity: number;
      _uniq: string;
      cycleTimw: number;
    },
  ];
  updatedMachine: {
    machineName: string;
    machineStyle: string;
    moreDetails: object;
    operations: [
      {
        name: string;
        style: string;
      },
    ];
  };
  layout: {
    machineName: string;
    operations: IOperationRunResults[];
    totalCutTime: number;
    totalIdleTime: number;
    setupTime: number;
    loadTime: number;
    grossProduction: number;
    cycleTime: number;
  };
}
