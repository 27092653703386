import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Auth } from './auth.interface';

export const getAuth = createFeatureSelector<Auth>('auth');
export const getLoggedIn = createSelector(getAuth, (auth: Auth) => auth.loggedIn);
export const getActiveDomain = createSelector(getAuth, (auth: Auth) => auth.activeDomain);
export const getRoles = createSelector(getAuth, (auth: Auth) => auth.roles);
export const getPrivileges = createSelector(getAuth, (auth: Auth) => auth.privileges);
export const getLoggedInWthToken = createSelector(getAuth, (auth: Auth) => auth.loggedInWithToken);
export const getEmailUpdateConfirmed = createSelector(getAuth, (auth: Auth) => auth.emailUpdateConfirmed);
export const getRegistrationConfirmed = createSelector(getAuth, (auth: Auth) => auth.registrationConfirmed);
export const getUser = createSelector(getAuth, (auth) => auth.user);
export const getStatus = createSelector(getAuth, (auth) => auth.status);
export const getAccessCompanies = createSelector(getAuth, (auth)=>auth.accessCompanies)
export const getLoginFailed = createSelector(getAuth, (auth) => {
  return { loginFailed: auth.loginFailed, error: auth.error };
});
export const getRegisterRequestFailed = createSelector(getAuth, (auth) => {
  return { registerRequestFailed: auth.registerRequestFailed, error: auth.error };
});
export const getPasswordReset = createSelector(getAuth, (auth) => auth.passwordReset);
export const getPasswordResetRequestSent = createSelector(getAuth, (auth) => auth.passwordResetRequestSent);
export const getPasswordResetRequestResult = createSelector(getAuth, (auth) => auth.passwordResetRequestResult);
export const getBrandingCompanyInfo = createSelector(getAuth, (auth) => auth.brandingCompanyInfo);
export const getAuthError = createSelector(getAuth, (auth) => auth.error);
export const getUserInviteToken = createSelector(getAuth, (auth) => auth.userInviteToken);

export const authQuery = {
  getAuth,
  getLoggedIn,
  getUser,
  getActiveDomain,
  getRoles,
  getPrivileges,
  getAccessCompanies,
  getLoginFailed,
  getLoggedInWthToken,
  getEmailUpdateConfirmed,
  getRegistrationConfirmed,
  getPasswordReset,
  getPasswordResetRequestSent,
  getPasswordResetRequestResult,
  getRegisterRequestFailed,
  getBrandingCompanyInfo,
  getAuthError,
  getStatus,
  getUserInviteToken,
};
