import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { LocalStorageKeys, LocalStorageService } from '../local-storage-jwt.service';
import { RedirectUrlService } from '../redirect-url.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private storage: LocalStorageService, private router: Router, private actRoute: ActivatedRoute, private redirectService: RedirectUrlService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const storageKey = this.useJwtTokenFromUrl(request.url)
      ? LocalStorageKeys.jwtTokenFromUrl
      : LocalStorageKeys.jwtToken;
    return this.storage.getItemObs(storageKey).pipe(
      take(1),
      map((token) => {
        const noauth = request.headers.get('noauth');
        return request.clone(token && noauth !== 'true' ? { setHeaders: { Authorization: `Bearer ${token}` } } : {});
      }),
      switchMap((req) => {
        // next.handle(req)
        return next.handle(req).pipe(
          catchError((error: HttpErrorResponse) => {
            let reroute = this.rerouteOnError(req.url);
            const path = this.router.url.split('?')[0].trim();
            if (reroute && error.status === 401) {
              const urlTree = this.redirectService.clearContextAndGetRedirectUrlTree('');
              this.router.navigateByUrl(urlTree);
            } else if (reroute && error.status === 403 && path !== '/admin/quotes/client') {
              this.router.navigateByUrl('/admin/quotes/client');
            }
            return throwError(error);
          }),
        );
      }),
    );
  }

  rerouteOnError(currentUrl: string): boolean {
    const path = currentUrl.split('/'); // consider removing path
    // console.debug('Reroute error', currentUrl, path);

    // don't reroute if path is "confirmEmailUpdate"
    if (
      (path.length >= 4 && path[3].toLowerCase() === 'confirmchangeemail') ||
      currentUrl.includes('confirmchangeemail')
    ) {
      return false;
    }
    // don't reroute if path is "confirmRegister"
    else if (
      (path.length >= 4 && path[3].toLowerCase() === 'confirmregister') ||
      currentUrl.includes('confirmregister')
    ) {
      return false;
    }

    return true;
  }

  private useJwtTokenFromUrl(requestUrl: string) {
    // for the following routes pass in the jwt token that was received via url param:
    // /api/profile/confirmRegister
    // /api/profile/confirmChangeEmail
    // /api/profile/{userId}/resetPassword

    if (requestUrl.includes('/auth/confirmRegister')) {
      return true;
    }
    if (requestUrl.includes('/auth/confirmChangeEmail')) {
      return true;
    }
    if (requestUrl.includes('/auth') && requestUrl.endsWith('resetPassword')) {
      return true;
    }

    return false;
  }
}
