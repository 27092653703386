import cloneDeep from 'lodash/cloneDeep';

export function fill<T extends object>(baseObj: T, partialObj: Partial<T>) {
  const filledObj = cloneDeep(baseObj);
  Object.keys(partialObj).forEach((key) => {
    // only copy over values of properties that the base object already has
    if (baseObj.hasOwnProperty(key) && partialObj[key] != null) {
      const baseVal = baseObj[key];
      const valType = typeof baseVal;
      if (valType === 'object' && Array.isArray(baseVal)) {
        if (baseVal.length > 0) {
          const baseArrayItem = baseVal[0];
          const partialArray = partialObj[key];
          filledObj[key] = partialArray.map((x) => fill(baseArrayItem, x));
        }
      } else if (valType === 'object' && baseVal === null && typeof partialObj[key] === 'number') {
        filledObj[key] = partialObj[key];
      } else if (valType === 'object') {
        filledObj[key] = fill(baseObj[key], partialObj[key]);
      } else {
        filledObj[key] = partialObj[key];
      }
    }
  });
  return filledObj;
}
