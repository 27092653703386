export enum stockType {
    Bar = 'Bar',
    Coil = 'Coil',
    Tube = 'Tube',
    Beam = 'Beam',
    Casting = 'Casting',
    Forging = 'Forging',
    Sheet = 'Sheet',
    Plate = 'Plate',
    Channel = 'Channel',
    Extrusion = 'Extrusion',
    Pipe = 'Pipe',
    PartBlank = 'Part Blank',
  }