import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ActiveDomainInterceptorService } from './interceptors/active-domain-interceptor.service';
import { TokenInterceptorService } from './interceptors/token-interceptor.service';
import { LocalStorageService } from './local-storage-jwt.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    TokenInterceptorService,
    LocalStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ActiveDomainInterceptorService,
      multi: true,
    },
  ],
})
export class UiAuthHandlersModule {}
