export const SKIP_TUTORIAL_VALUE = 99;
export const E_ProgressTrackingTypes = {
  TUTORIAL: 'TUTORIAL',
};

export const E_ProgressTrackingTutorialKeys = {
  QUOTES: 'QUOTES',
  PROFILE: 'PROFILE',
  SETTINGS: 'SETTINGS',
  COMPANY: 'COMPANY',
  USERS: 'USERS',
  LICENCES: 'LICENCES',
  LABORCODES: 'LABORCODES',
  EDITLABORCODE: 'EDITLABORCODE',
  FEATURES: 'FEATURES',
  OPERATIONS: 'OPERATIONS',
  MACHINES: 'MACHINES',
  CONTACTS: 'CONTACTS',
  NEWQUOTE: 'NEWQUOTE',
  NEWUSER: 'NEWUSER',
  NEWCONTACT: 'NEWCONTACT',
  DUPLICATEOP: 'DUPLICATEOP',
  ARCHIVEDMACHINES: 'ARCHIVEDMACHINES',
  EDITMACHINES: 'EDITMACHINES',
  DUPLICATEFEATURE: 'DUPLICATEFEATURE',
  NEWFEATURE: 'NEWFEATURE',
  NEWQUOTEPART: 'NEWQUOTEPART',
};

export interface IProgressTrackingRequest {
  company?: string;
  user: string;
  type: string;
  key: string;
  progress: number;
  total: number;
  complete: boolean;
}

export interface IBusinessProgressTrackingRequest extends IProgressTrackingRequest {
  id: string;
}
