import { IRolePrivileges } from './role-privileges';

export interface IToken {
  email: string;
  exp: number;
  iat: number;
  userId: string;
  activeDomain: string;
  roles: { companyId: string; companyName: string; role: string }[];
  privileges?: IRolePrivileges[];
  password?: string;
}
