import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertFacade } from '@zc/ui-alert';
import { Subscription } from 'rxjs';

@Component({
  selector: 'zc-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent implements OnInit, OnDestroy {
  newAlerts: Subscription = new Subscription();

  firstRun = true;
  showBar = false;

  constructor(private alertFacade: AlertFacade) {}

  ngOnInit(): void {
    this.alertFacade.allAlerts$.subscribe((alerts) => {
      //console.log('Loading alerts', alerts);

      const hasAlerts = alerts.length;

      // Empty message alert will clear the alerts
      if (hasAlerts) {
        // Display snackbar
        this.showBar = true;
      } else {
        // & HACK to avoid first run flutter
        this.firstRun = false;
      }

      // hide if empty
      this.showBar = !!hasAlerts;
    });
  }

  ngOnDestroy(): void {
    this.newAlerts.unsubscribe();
  }
}
