import { Types } from 'mongoose';

export interface ILicenseLevel {
  name: string;
  seller: string;
  stripeProductId: string;
  description: string;
  type: LicenseLevelType;
  shouldCostInfo?: {
    roles: string[];
    usage?: {
      uOpCredits: number;
    };
  };
  mesInfo?: {
    precisionOptionItemId: string;
    precisionOptionTypeId: number; // corresponds to Precision’s SystemOptionId
    precisionOptionType: string; // i.e. “Screw Machines”, “Fabrication”…
    precisionPrice: number;
    mseId?: string;
    isPackage: boolean;
    isOneTimeCharge: boolean;
  };
}

export interface IBusinessLicenseLevel extends ILicenseLevel {
  _id?: Types.ObjectId;
  id?: string;
}

export enum LicenseLevelType {
  Mes = 'Mes',
  ShouldCost = 'ShouldCost',
  ImportedMes = 'ImportedMes',
}

export interface CreateSubscriptionResponse {
  subscriptionId: string;
  client_secret: string;
}

export interface IPurchaseSubscriptionsStatus {
  status: string;
  subscriptionId?: string;
  paymentMethodId?: string;
  client_secret?: string;
  stripeCardError?: string;
  error?: string;
}

export enum SHOULDCOST_APPS {
  SCDEEP = 'ShouldCost DEEP',
  SC = 'ShouldCost',
}
