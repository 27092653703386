import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { FullRouterStateSerializer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  CustomRouterStateSerializer,
  reducers,
  RouterEffects,
  RouterFacade,
  RouterStoreExtension,
} from '@zc/app-router';
import { AuthModule } from '@zc/auth';
import { UiAlertModule } from '@zc/ui-alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { environment } from '../../environments/environment'; // Angular CLI environment
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers, {
      metaReducers: [],
      runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true },
    }),
    StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer, stateKey: 'router' }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      // logOnly: environment.production, // Restrict extension to log-only mode
      // autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    EffectsModule.forRoot([RouterEffects]),
    BsDropdownModule.forRoot(),
    // !isProd ? StoreDevtoolsModule.instrument({ maxAge: 25 }) : [],
    TypeaheadModule.forRoot(),
    PopoverModule.forRoot(),
    GuidedTourModule,
    AppRoutingModule,
    UiAlertModule,
    AuthModule,
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    RouterStoreExtension,
    RouterFacade,
    GuidedTourService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private rse: RouterStoreExtension) {}
}
