export interface MainPayment {
  name: string;
  state: string;
  parts: {
    name: string;
    quantity: number;
    cost: number;
  }[];
}

export interface IPayment extends MainPayment {
  stripePaymentId: string;
  date: Date;
}

export interface IBusinessPayment extends IPayment {
  id: string;
}

export interface Payment {
  name: string;
  status: string;
  contents: Content[];
}

export interface Content {
  name: string;
  quantity: number;
  cost: number;
}

export interface BusinessPayment extends Payment {
  id: string;
}
