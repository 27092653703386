export interface IMesLicenseData {
  ScCompanyID: string;
  ScUserID: string;
  CompanyName: string;
  CompanyCode: number;
  EUsers: number;
  CUsers: number;
  AFRUsers: number;
  LicensedVersion: number;
  MaintExpDate: Date;
  ExpirationDate: Date;
  PerpetualLicense: boolean;
  KeyType: string;
  AFR2d: boolean;
  ToolLibrary: boolean;
  AnalysisTool: boolean;
  MaintenanceValue: number;
  MaintenanceAmount: number;

  INFOR: boolean;
  MIETrak: boolean;
  FloatingLicense: boolean;
  Assembly_Renamed: boolean;
  ReportWriter: boolean;
  DataExport: boolean;
  SQLServer: boolean;
  ACTInterface: boolean;
  Dashboard: boolean;
  OracleMFG: boolean;
  VisualMFG: boolean;
  ProfitKey: boolean;
  M1: boolean;
  RealTrak: boolean;
  E2: boolean;
  GlobalShop: boolean;
  EstiTrak: boolean;
  JobBoss: boolean;
  Epicor: boolean;
  AFR3d: boolean;

  WC: boolean[];
}

// public const int KEYTYPE_NOTFOUND = -1;
//         public const int KEYTYPE_CLIENT = 1;
//         public const int KEYTYPE_NETINEL = 3;
//         public const int KEYTYPE_TEMPORARY = 4;
//         public const int KEYTYPE_CLOUD = 5;
//         public const int KEYTYPE_SUPERPRO = 100;
//         public const int KEYTYPE_SUPERPRONET = 102;
//         public const int KEYTYPE_ACTIVATOR = 104;

// ERP integration
export enum ERPSystemEnum {
  ERPSystemNone = 0,
  ERPSystemPROFITKEY = 1,
  ERPSystemE2 = 2,
  ERPSystemJobboss = 3,
  ERPSystemEstiTrack = 4,
  ERPSystemEpicor = 5,
  ERPSystemINFOR = 6,
  ERPSystemM1 = 7,
  ERPSystemGlobalshop = 8,
  ERPSystemRealtrac = 9,
  ERPSystemVisualMFG = 10,
  ERPSystemOracleMFG = 11,
  ERPSystemMIETrak = 12,
  ERPSystemMax = 12,
}

export enum WorkcenterID {
  WorkcenteridNONE = 0,
  WorkcenteridACME = 1,
  WorkcenteridBROACH = 3,
  WorkcenteridBORE = 4,
  WorkcenteridHARDINGE = 5,
  WorkcenteridDRILLPRESS = 6,
  WorkcenteridEDM = 8,
  WorkcenteridWARNERANDSWAYSEY = 9,
  WorkcenteridDAVENPORT = 10,
  WorkcenteridGREENLEE = 11,
  WorkcenteridCONOMATIC = 12,
  WorkcenteridGEARCUTTING = 13,
  WorkcenteridGUNDRILL = 14,
  WorkcenteridHONE = 15,
  WorkcenteridINSPECTION = 17,
  WorkcenteridBRIDGEPORT = 18,
  WorkcenteridMANUALLATHE = 19,
  WorkcenteridLAP = 20,
  WorkcenteridCNCMILL = 21,
  WorkcenteridMANUALMILL = 22,
  WorkcenteridBROWNANDSHARPE = 23,
  WorkcenteridCNCLATHE = 24,
  WorkcenteridPAINT = 25,
  WorkcenteridCOILPUNCH = 28,
  WorkcenteridSAW = 29,
  WorkcenteridSTAMP = 30,
  WorkcenteridSTRAIGHTEN = 31,
  WorkcenteridWICKMAN = 32,
  WorkcenteridSTANDARDS = 35,
  WorkcenteridCAMSWISS = 36,
  WorkcenteridCNCSWISS = 37,
  WorkcenteridNEWBRITTAIN = 38,
  WorkcenteridANGLEGRIND = 39,
  WorkcenteridCOLLETGRIND = 40,
  WorkcenteridRECIPROCALSURFACEGRIND = 41,
  WorkcenteridROTARYSURFACEGRIND = 42,
  WorkcenteridCENTERLESSGRIND = 43,
  WorkcenteridTHREAD = 44,
  WorkcenteridINLINETRANSFER = 45,
  WorkcenteridROTARYTRANSFER = 46,
  WorkcenteridINTERNALGRIND = 49,
  WorkcenteridGRINDINGMACHINES = 49,
  WorkcenteridPLUNGEGRIND = 50,
  WorkcenteridTRAVERSEGRIND = 51,
  WorkcenteridCASTING = 52,
  WorkcenteridMOLDING = 53,
  WorkcenteridTIMEANDMATERIALS = 55,
  WorkcenteridKMX = 56,
  WorkcenteridINDEXMS = 57,
  WorkcenteridTORNOSSAS = 59,
  WorkcenteridINDEXGB = 60,
  WorkcenteridBORINGBAR = 65,
  WorkcenteridSPREADSHEET = 66,
  WorkcenteridCYLINDRICALGRIND = 67,
  WorkcenteridWELD = 68,
  WorkcenteridTURRETPUNCH = 69,
  WorkcenteridLASERPUNCH = 70,
  WorkcenteridPLASMAPUNCH = 71,
  WorkcenteridLASER = 72,
  WorkcenteridPLASMA = 73,
  WorkcenteridWATERJET = 74,
  WorkcenteridOXYFUEL = 75,
  WorkcenteridPRESSBRAKE = 76,
  WorkcenteridHANDFABRICATION = 77,
  WorkcenteridNIBBLE = 78,
  WorkcenteridPOWERSHEAR = 79,
  WorkcenteridJUMPSHEAR = 80,
  WorkcenteridSINGLESTATIONPUNCH = 81,
  WorkcenteridSECONDARYPUNCH = 82,
  WorkcenteridGILDEMEISTER = 83,
  WorkcenteridGEARHOBB = 84,
  WorkcenteridGEARSHAVE = 85,
  WorkcenteridGEARSHAPE = 86,
  WorkcenteridEXCEL = 87,
  WorkcenteridMax = WorkcenteridEXCEL,
}

export enum CompanyCodeEnum {
  MicroEstimatingCompanyCode = 1,
  AnchorDanlyCompanyCode = 3269,
  MetaldyneCompanyCode = 3317,
  MazakCompanyCode = 3272,
  HelgesenCompanyCode = 3366,
  EIMCOCompanyCode = 3556,
  MiltonManufacturingCompanyCode = 8145,
  SeastromCompanyCode = 3512,
  TriPartScrewProductsCompanyCode = 2207,
  NASAGSFCCompanyCode = 8191,
  SwagelokCompanyCode = 2138, // same company as Mansfield Screw
}

export enum SystemOptionIDEnum {
  CNCMachinesSystemOptionID = 2,
  ScrewMachinesSystemOptionID = 3,
  GeneralManufacturingSystemOptionID = 4,
  FabricationSystemOptionID = 5,
}

export enum DatabaseTypeEnum {
  JetDatabaseType = 0,
  SQLDatabaseType = 1,
}
