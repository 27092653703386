import { createAction, props } from '@ngrx/store';
import { Alert } from '../../alert/alert.model';

export const addAlert = createAction(
  '[Alert/API] Add Alert',
  props<{ alert: Alert }>()
);

export const addAlertSuccess = createAction(
  '[Alert/API] Add Alert Success',
  props<{ alert: Alert }>()
);

export const removeAlert = createAction(
  '[Alert/API] Remove Alert',
  props<{ alert: Alert }>()
);

export const removeAlertSuccess = createAction(
  '[Alert/API] Remove Alert Success',
  props<{ alert: Alert }>()
);
