export interface ILookupToken {
  type: string;
  exp: Date;
}

export interface IBetaToken extends ILookupToken {
  company: string; // company._id
  contactId?: string;
  userId?: string;
}

export interface IBusinessLookupToken extends IBetaToken {
  id: string;
}

export interface IBusinessUserLookupToken extends IBusinessLookupToken {
  userEmail: string;
  userHasRightsToOtherDomains: boolean;
}

export enum E_LookupTokenTypes {
  betaInvite = 'beta-invite',
  contactInvite = 'contact-invite',
  userInvite = 'user-invite',
}
