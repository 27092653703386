import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Alert, AlertType } from '../../alert/alert.model';
import * as AlertActions from './alert.actions';
import * as AlertSelectors from './alert.selectors';

@Injectable()
export class AlertFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  allAlerts$ = this.store.pipe(select(AlertSelectors.getAllAlerts));
  public defaultOptions: Partial<Alert> = {
    keepAfterRouteChange: true,
    timeout: 10000, // 10 sec
  };
  private defaultId = 'default-alert';
  private alertNext = 0;

  constructor(private readonly store: Store) {}

  // convenience methods
  success(message: string, options?: Partial<Alert>) {
    this.alert(new Alert({ ...options, type: AlertType.Success, message }));
  }

  error(message: string, options?: Partial<Alert>) {
    this.alert(new Alert({ ...options, type: AlertType.Error, message }));
  }

  info(message: string, options?: Partial<Alert>) {
    this.alert(new Alert({ ...options, type: AlertType.Info, message }));
  }

  warn(message: string, options?: Partial<Alert>) {
    this.alert(new Alert({ ...options, type: AlertType.Warning, message }));
  }

  // main alert method
  alert(alert: Alert) {
    alert.id = alert.id || `${this.defaultId}__${++this.alertNext}`;
    this.store.dispatch(AlertActions.addAlert({ alert }));
  }

  // clear alerts
  clear(id = this.defaultId) {
    this.store.dispatch(AlertActions.addAlert({ alert: new Alert({ id }) }));
  }

  remove(alert: Alert) {
    this.store.dispatch(AlertActions.removeAlert({ alert }));
  }
}
