export enum WorkType {
  SandCasting = 'Sand Casting',
  InvestmentCasting = 'Investment Casting',
  PlasterCasting = 'Plaster Casting',
  CNCMachining = 'CNC Machining',
  PrintedPatternInvestmentCasting = '3D Printed Pattern Casting',
  PrintedPatternSandCasting = '3D Printed Sand Casting',
  PrintedPMMA = 'Printed PMMA Mold',
}

export const toWorkType = (workType: string): WorkType | undefined => {
  for (let wt in WorkType) {
    const key = wt as keyof typeof WorkType;
    const val = WorkType[key];
    if (workType.toLowerCase() === val.toLowerCase()) {
      return val;
    }
  }

  return undefined;
};
