import { UserRole } from '../enumerations/user-role';
import { IRolePrivileges } from './';

export interface IUserRole {
  companyId: string;
  role: UserRole;
}
export interface IUserInfo {
  userId: string;
  name: string;
  email: string;
  roles: IUserRole[];
  privileges?: IRolePrivileges[];
  company?: string;
  team: string;
  phone: {
    office: string;
    mobile: string;
  };
  notes?: string;
  precisionContactId?: number;
  accessCompanies: any[]; // string or objectid
  availableCompanies: any[];
  unverifiedCompanies: any[];
}

export interface IBusinessUserInfo extends IUserInfo {
  id: string;
}
export interface IUser extends IUserInfo {
  password?: string;
  verified: boolean;
  // verificationCode: string;
  verificationDate: Date | null;
}

export interface IBusinessUser extends IUser {
  id: string;
}

export interface Profile {
  id: string;
  name: string;
  email: string;
  phone: {
    office: string;
    mobile: string;
  };
}

export enum ProfileDisplay {
  View = 'view',
  EditDetails = 'edit details',
  EditEmail = 'edit email',
  EditEmailSuccess = 'edit email success',
  EditEmailFailure = 'edit email failure',
  EditPassword = 'edit password',
}
