import { IQuoteMetric } from './quote-metric';

export interface IQuoteCalculatorRequest extends IQuoteMetric {
  machineConfig?: any;
  material?: any;
  operations?: any[];
  tool?: any;
}

export interface IBusinessQuoteCalculatorRequest extends IQuoteCalculatorRequest {
  id: string;
}

export interface IQuoteCalculatorRequestSummary extends IQuoteMetric {
  operations?: any[];
  quotes?: any[];
  requestIds?: any;
}

export interface IQuoteCalculatorRequestUserSummary extends IQuoteMetric {
  uOps?: number; // Unique Operation IDs
  uQuotes?: number; // Unique Quote IDs
}

export interface IQuoteCalculatorRequestBilling {
  companyResults?: number; // Unique Quote IDs
  uOps?: number; // Unique Operation IDs
}

export interface IQuoteCalculatorRequestDailyUserOperationCount {
  date: Date;
  operationCount: number;
  user: string;
}
