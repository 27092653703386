export interface IResults {
    category: string;
    values: IResult[];
}

export interface IResult {
    id: string;
    name: string;
    amount: number;
    unit: string;
    editable: boolean;
    edited?: boolean;
    display?: string;
}
