import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { NavigateTo } from './app-router.actions';
import { IAppState } from './reducers';
import { routerQuery } from './app-router.selectors';

@Injectable()
export class RouterFacade {
  currentRoute$ = this.store.pipe(select(routerQuery.getState));
  previousRoute$ = this.store.pipe(select(routerQuery.getPreviousRoute));

  constructor(private store: Store<IAppState>) {}

  navigateTo(url: string | (string | number)[], extras?: NavigationExtras) {
    this.store.dispatch(new NavigateTo({ url, extras }));
  }
}
