import { UserResourceType } from '@zc/api';
import { INewFeature } from '@zc/api';

export interface IUserResource {
  userId: string;
  companyId: string;
  type: UserResourceType;
  key: string;
  uploaded: Date;
  size: number;
}

export interface IFileResource extends IUserResource {
  signedUrl?: string;
  convertedId?: string;
  convertable: boolean;
  thumbnailId?: string;
  analysis?: Record<string, any>;
  camFeatures: INewFeature[];
}

export interface IBusinessFileResource extends IFileResource {
  id: string;
}
