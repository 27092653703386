import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as AlertActions from './alert.actions';
import { AlertEntity } from './alert.models';

export const ALERT_FEATURE_KEY = 'alert';

export interface State extends EntityState<AlertEntity> {
  selectedId?: string | number; // which Alert record has been selected
}

export interface AlertPartialState {
  readonly [ALERT_FEATURE_KEY]: State;
}

export const alertAdapter: EntityAdapter<AlertEntity> =
  createEntityAdapter<AlertEntity>();

export const initialState: State = alertAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const { selectAll } = alertAdapter.getSelectors();
const alertReducer = createReducer(
  initialState,
  on(AlertActions.addAlert, (state, { alert }) => {
    //console.log('Adding alert', alert);
    // clear alerts when an empty alert is received
    if (!alert.message) {
      // filter out alerts without 'keepAfterRouteChange' flag
      const alerts = selectAll(state).filter((x) => x.keepAfterRouteChange);

      // remove 'keepAfterRouteChange' flag on the rest
      const updatedAlerts = alerts.map((x) => ({
        ...x,
        keepAfterRouteChange: false,
      }));
      //console.log('Clearing alerts', alerts);
      return alertAdapter.setAll(updatedAlerts, { ...state });
    }

    return alertAdapter.addOne(alert, { ...state });
  }),
  on(AlertActions.removeAlert, (state, { alert }) => {
    let alerts = selectAll(state);
    //console.log('removing alert', alert, alerts);
    // check if already removed to prevent error on auto close
    if (!alerts.includes(alert)) return { ...state };

    alerts = alerts.filter((x) => x !== alert);
    //console.log('alert removed', alerts);
    return alertAdapter.setAll(alerts, { ...state });
  })
);

export function reducer(state: State | undefined, action: Action) {
  return alertReducer(state, action);
}
