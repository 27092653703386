import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';

import { AuthService, AuthUser, ILoginResponse } from './auth.service.interface';
import { IUser } from 'libs/api/src/interfaces';
import { UserResponse } from './+state/auth.interface';

@Injectable()
export class MockAuthService implements AuthService {
  user(): Observable<UserResponse> {
    return of();
  }

  login(user: Partial<AuthUser>): Observable<ILoginResponse> {
    if (user && user.email === 'bla@go.hu' && user.password === '1234') {
      return of({
        data: {
          token: 'mockToken',
        },
      });
    }

    throw new Error('Unauthorized error');
  }
  register(user: Partial<IUser>): Observable<Partial<UserResponse>> {
    return of({});
  }
}
