export enum ImportedMesPlanType {
    NewSystem = 1,
    AddOn = 2,
    Miscellaneous = 3
}

export interface IImportedMesPlan {
    buyer: string,
    name: string,
    //licenseLevels: any[],
    dateSold?: Date,
    status: string,
    precisionQuoteId?: number,
    EUsers: Number,
    CUsers: Number,
    AFRUsers: Number,
    additionalSeatDiscountPct: Number,
    discountPct: Number,
    discountAmt: Number,
    upchargeAmt: Number,
    trainingAmt: Number,
    firstSeatAmt: Number,
    additionalSeatAmount: Number,
    oneTimeAmount: Number,
    adminSeatAmount: Number,
    AFRAmount: Number,
    maintenanceValue: Number,
    maintenanceAmount: Number,
    maintenancePct: Number,
    totalAmount: Number,
    expirationDate: Date,
    dateOrdered?: Date,
    type: Number,
}

export interface IBusinessImportedMesPlan extends IImportedMesPlan {
    _id?: string
}