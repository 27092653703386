export interface ItemDetail {
  label: string;
}

export interface ISingleItemDetail<T> extends ItemDetail {
  value: T;
}

export interface IBasicItemDetail<T> extends ItemDetail {
  value: T;
  options: T[];
}

export interface IUnlabeledRowsTableItemDetail<T> extends ItemDetail {
  columns: string[];
  values: T[][];
  value?: null;
}

export interface ITableItemDetail<T> extends ItemDetail {
  rows: string[];
  columns: string[];
  values: T[][];
  value?: null;
}

export type VariableType<T> =
  | ISingleItemDetail<T>
  | IBasicItemDetail<T>
  | IUnlabeledRowsTableItemDetail<T>
  | ITableItemDetail<T>
  | null;

export const EmptyItemDetail: ItemDetail = {
  label: '',
};
Object.freeze(EmptyItemDetail);
export const EmptySingleItemDetail: ISingleItemDetail<any> = {
  label: '',
  value: '',
};
Object.freeze(EmptySingleItemDetail);
export const EmptyBasicItemDetail: IBasicItemDetail<any> = {
  label: '',
  value: '',
  options: [],
};
Object.freeze(EmptyBasicItemDetail);
export const EmptyUnlabeledRowsTableItemDetail: IUnlabeledRowsTableItemDetail<any> = {
  label: '',
  columns: [],
  values: [[]],
};
Object.freeze(EmptyUnlabeledRowsTableItemDetail);
export const EmptyTableItemDetail: ITableItemDetail<any> = {
  label: '',
  rows: [],
  columns: [],
  values: [[]],
};
Object.freeze(EmptyTableItemDetail);

const isInstanceOf = (template, obj): boolean => {
  let containsMembers = true;
  Object.keys(template).forEach((member) => {
    containsMembers = containsMembers && member in obj;
  });
  return containsMembers;
};

export const isItemDetail = (obj): boolean => {
  return isInstanceOf(EmptyItemDetail, obj);
};

// export const isSingleItemDetail = (obj): boolean => {
//   return isInstanceOf(EmptySingleItemDetail, obj);
// };

export const isBasicItemDetail = (obj): boolean => {
  return isInstanceOf(EmptyBasicItemDetail, obj);
};

export const isUnlabeledRowsTableItemDetail = (obj): boolean => {
  return isInstanceOf(EmptyUnlabeledRowsTableItemDetail, obj);
};

export const isTableItemDetail = (obj): boolean => {
  return isInstanceOf(EmptyTableItemDetail, obj);
};
