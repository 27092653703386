import { IBusinessOperation, INewOperation, IOperation, IOperationManualValues } from './operation';
import { Machine } from './machine';
import { Material } from './material';
import { WorkflowCost, ILaborCode } from './cost';

export enum WorkState {
  PENDING = 'PENDING',
  DONE = 'DONE',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum WorkflowLayoutChangeKeys {
  SPEED = 'Speed',
  FEED = 'Feed',
  RPM = 'RPM',
  TOOL_RPM = 'ToolRPM',
  CUT_LENGTH = 'CutLength',
  IN_MIN = 'InMin',
  CUT_MINUTES = 'CutMinutes',
  IDLE_MINUTES = 'IdleMinutes',
}

export interface MainWorkFlowMachine {
  name?: string;
  machine: string;
  spindle?: string;
  machineSetup: any;
  selectedCostGroup?: ILaborCode;
}
export interface WorkflowLayoutValueChange {
  workflowId: string;
  rowId: string;
  key: string;
  value: number;
}

export interface BusinessWorkflowMachine extends MainWorkFlowMachine {
  id: string;
  operations: IBusinessOperation[];
}

export interface WorkflowLayoutOperation {
  id?: string | number;
  manualValues?: IOperationManualValues;
}
export interface WorkflowRequest extends MainWorkFlowMachine {
  id?: string;
  operations: WorkflowLayoutOperation[];
}

export interface ILayoutSetupTime{
  description: string;
  hours: number;
}
export interface ILayout {
  workflowId?: string;
  machineId?: string;
  machine?: any;
  style: string;
  operations: any[]; // TODO change to valid operations later
  // {
  //   id?: string;
  //   name: string;
  //   idleTime: number;
  //   totalTime: number;
  //   quantity: number;
  // }[];
  totalCutTime?: number;
  totalIdleTime?: number;
  setupTime?: number;
  setupTimes?: ILayoutSetupTime[];
  loadTime?: number;
  grossProduction?: number;
  cycleTime?: number;
}

export interface IWorkflowListLayout extends ILayout {
  machine: Machine;
  workflow: IPricedWorkflow;
  selectedCostGroup: any; //ILaborCode;
  cost: number;
  additionalCost: number;
}

interface IWorkflow {
  _id?: any;
  id: any;
  style: string;
  material?: any;
  stock?: any;
  machine: any;
  machineSetup?: any;
  tool?: any;
  selectedCostGroup?: ILaborCode; // CostGroup (formerly LaborCode) Object
  costs?: any;
  calculatorError?: string;
  machineStyle?: string;
}

export interface INewWorkflowItem extends IWorkflow {
  operations: INewOperation[];
}

export interface INewWorkflowLayout extends IWorkflow {
  operations: WorkflowLayoutOperation[];
  updated?: boolean;
}

export interface IPricedWorkflow extends INewWorkflowLayout {
  runtime: number;
  cost: number;
  additionalCost: number;
}

export interface IWorkflowItemArray extends Array<INewWorkflowItem> {}

export interface IBusinessWorkflow {
  id: string;
  workflow: IWorkflowItemArray;
}
