export enum FileType {
  CAD_SOLID = 'CAD_SOLID',
  CAD_MESH = 'CAD_MESH',
  DRAWING = 'DRAWING',
  DOCUMENT = 'DOCUMENT',
}

export const solidFileExtensions = [
  '.igs',
  '.iges',
  '.sat',
  '.saa',
  '.sab',
  '.sld',
  // '.sldprt',
  // '.slddrw',
  '.step',
  '.stp',
  '.stl',
  '.vda',
  '.vda-fs',
  '.vrml',
  '.wrl',
  '.x_t',
  '.xmt_txt',
  '.x_b',
  '.xmt_bin',
];

export enum FileUnit {
  INCH = 'in',
  FOOT = 'ft',
  MILLIMETER = 'mm',
  CENTEMETER = 'cm',
  METER = 'm',
}

export interface File {
  name: string;
  key: string;
  type?: FileType;
}

export interface BusinessFile extends File {
  id: string;
}
