import { IUserInfo } from './user';

export interface IContact {
  name: string;
  email?: string;
  companyName?: string;
  company: string;
  phone: {
    office?: string;
    mobile?: string;
    home?: string;
    other?: string;
  };
}

export interface IContactLinked extends IContact {
  user?: string;
}

export interface IContactPopulated extends IContact {
  user?: IUserInfo;
}

export interface IBusinessContact extends IContactPopulated {
  id: string;
}
