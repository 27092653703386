import { INewOperation, INewFeature, INewCost } from 'libs/api/src/interfaces';

export interface IWork {
  _id?: any;
  id: string;
  name: string;
  laborCode: string;
  costs: INewCost[];
  operations: INewOperation[];
  features: INewFeature[];
  //calculationResults: IResults[];
  runtime: number;
  setupTime: number;
  loadTime: number;
  cycleTime: number;
  cost: number;
  additionalCost: number;
  type: string;
}
