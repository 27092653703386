export class PsudoObjectID {
  static index = ~~(Math.random() * 0xffffff);

  private id: any;
  private MACHINE_ID = parseInt((Math.random() * 0xffffff).toString(), 10);
  private hexTable: any[];

  constructor() {
    this.generateHexTable();

    // Generate a new id
    this.id = this.generate();
  }

  private generateHexTable() {
    this.hexTable = [];
    for (var i = 0; i < 256; i++) {
      this.hexTable[i] = (i <= 15 ? '0' : '') + i.toString(16);
    }
  }

  private hexToUtf8(hex) {
    return decodeURIComponent('%' + hex.match(/.{1,2}/g).join('%'));
  }

  private get_inc() {
    return (PsudoObjectID.index = (PsudoObjectID.index + 1) % 0xffffff);
  }

  private convertToHex() {
    let hexString = '';
    for (var i = 0; i < this.id.length; i++) {
      hexString += this.hexTable[this.id[i]];
    }
    return hexString;
  }

  public toHexString() {
    return this.convertToHex();
  }

  public generate(time?) {
    if ('number' !== typeof time) {
      time = ~~(Date.now() / 1000);
    }

    // Use pid
    var pid =
      (typeof process === 'undefined' || process.pid === 1 ? Math.floor(Math.random() * 100000) : process.pid) % 0xffff;
    var inc = this.get_inc();
    // Buffer used
    var buffer = new Array<number>(12);
    // Encode time
    buffer[3] = time & 0xff;
    buffer[2] = (time >> 8) & 0xff;
    buffer[1] = (time >> 16) & 0xff;
    buffer[0] = (time >> 24) & 0xff;
    // Encode machine
    buffer[6] = this.MACHINE_ID & 0xff;
    buffer[5] = (this.MACHINE_ID >> 8) & 0xff;
    buffer[4] = (this.MACHINE_ID >> 16) & 0xff;
    // Encode pid
    buffer[8] = pid & 0xff;
    buffer[7] = (pid >> 8) & 0xff;
    // Encode index
    buffer[11] = inc & 0xff;
    buffer[10] = (inc >> 8) & 0xff;
    buffer[9] = (inc >> 16) & 0xff;
    // Return the buffer
    return buffer;
  }
}
