import { Types } from 'mongoose';
import { IBusinessLicenseLevel } from '.';

export interface ILicensePlanDisplayInfoSummary {
  level: string;
  quantity: number;
  numLicenses: number;
}

export interface ILicensePlan {
  name: string;
  stripePriceId: string;
  // description: string,
  seller: string;
  level: string;
  numLicenses: number;
  // autoRenew: boolean,
  // maxLicenses: number,
}

export interface IBusinessLicensePlan {
  _id?: Types.ObjectId;
  id?: string;
  name: string;
  stripePriceId: string;
  description?: string;
  // seller: string | object,
  level: IBusinessLicenseLevel;
  numLicenses: number;
  // autoRenew: boolean,
}

export interface ILicensePlanDisplayInfo extends IBusinessLicensePlan {
  intervalType: string;
  intervalCount: number;
  amount: number;
  quantity?: number;
}

export interface ISubscriptionInfo {
  plans: ILicensePlanDisplayInfo[];
  type: 'new' | 'upgrade' | 'trial';
}
