import { IBusinessMachine } from '@zc/api';
import { VariableType } from './item';

export enum OperationOwnerType {
  company = 'company',
  system = 'system',
}

export interface INewOperation {
  _id?: any;
  id?: string;
  name: string;
  displayName?: string;
  opNumber?: number;
  style: string;
  variables: {
    [key: string]: VariableType<string | boolean | number | null>;
  };
  runtime: number;
  quantity: number;
  toolPosition?: string;
}

export interface IOperationManualValues {
  cutLength?: number;
  rpm?: number;
  speed?: number;
  feed?: number;
  inMin?: number;
  idleTime?: number;
}

export interface IOperationHeader {
  name: string;
  style: string;
  displayName?: string;
}

export interface IBusinessOperationHeader extends IOperationHeader {
  id: string;
}

export interface IOperation extends IOperationHeader {
  variables: {
    [key: string]: VariableType<string | boolean | number | null>;
  };
  owner: string;
  company?: string;
  runtime: number;
  quantity: number;
  opNumber: number;
  // cost: Cost;
}

export interface IBusinessOperation extends IOperation {
  id: string;
}

export interface IOperationRunResults {
  id?: string;
  name: string;
  idleTime: number;
  totalTime: number;
  cutLength?: number;
  cutTime?: number;
  speed?: number;
  feed?: number;
  rpm?: number;
  horsepower?: number;
  inMin?: number;
  toolPosition?: number;
  manualValues?: IOperationManualValues;
}

export const EmptyBusinessOperation: IBusinessOperation = {
  id: '',
  name: '',
  style: '',
  variables: {},
  owner: '',
  runtime: null,
  quantity: null,
  opNumber: null,
};
Object.freeze(EmptyBusinessOperation);

// export const toIBusinessOperation = (input: Partial<IBusinessOperation>):IBusinessOperation => {
//   const busOp = fill(EmptyBusinessOperation,)
// }

export const OperationStyles = {
  NCT: 'Turning',
  MCTR: 'Milling',
  LIVE: 'Turn/Milling',
  AFR: 'Advanced Feature Recognition',
  SAW: 'Sawing',
  STANDARDS: 'Standards',
  INSPECT: 'Inspection',
  DRILL: 'Drill',
  LATHE: 'Manual Lathe',
};

export const MachineStyles = {
  NCT: 'NCT',
  MCTR: 'MCTR',
  LIVE: 'LIVE',
  AFR: 'AFR',
  SAW: 'SAW',
  STANDARDS: 'STANDARDS',
  INSPECT: 'INSPECT',
  DRILL: 'DRILL',
  LATHE: 'LATHE',
};

export const OperationStylesFilter = (o) => o !== 'LIVE' && o !== 'AFR';

export const OperationsMatchMachine = (opStyle: any, machineStyle: any) => {
  return opStyle === machineStyle ||
  (machineStyle === MachineStyles.LIVE && (opStyle === OperationStyles.MCTR || opStyle === OperationStyles.NCT));
}
  

export interface IBusinessCalculatableOperation extends IBusinessOperation {
  machine: IBusinessMachine;
}

export interface ICompanyOperation extends IOperation {
  company: string;
}

export interface IBusinessCompanyOperation extends ICompanyOperation {
  _id?: any;
  id: any;
}
